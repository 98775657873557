const whitelistedDomain = [
  'tokentrove.com',
  'okx.com',
  'nftrade.com',
  'immutable.com',
  'localhost',
  'imx-marketplace-stg.devpq.com',
  'marketplace.planetquest.io',
];

export interface ExperimentalOptions {
  runCondition: boolean;
  checkReferrer: boolean;
  message: string;
}

export const UNAUTHORIZED_PARTNER_ERROR =
  'You are not whitelisted for experimental features';

export async function experimental<T>(
  callback: () => Promise<T>,
  options: ExperimentalOptions,
): Promise<T> {
  if (options.runCondition) {
    console.warn(options.message);

    if (options.checkReferrer && !isWhitelisted()) {
      throw new Error(UNAUTHORIZED_PARTNER_ERROR);
    }
  }

  return callback();
}

const isWhitelisted = (): boolean => {
  return !!whitelistedDomain.find(domain =>
    window.location.origin.includes(domain),
  );
};
